<template>
  <Drawer title="日志信息" v-model="isShow" width="600" scrollable>
    <Row class="text-cell">
      <Col class="title" span="3">编号:</Col>
      <Col class="value" span="9">{{ obj.ID }}</Col>
      <Col class="title" span="3">用户名:</Col>
      <Col class="value" span="8">{{ obj.UserName }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">模块名:</Col>
      <Col class="value" span="9">{{ obj.ModuleName }}</Col>
      <Col class="title" span="3">订单编号/摘要:</Col>
      <Col class="value" span="8">{{ obj.Title }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">IP地址:</Col>
      <Col class="value" span="9">{{ obj.IP }}</Col>
      <Col class="title" span="3">错误等级:</Col>
      <Col class="value" span="8">{{ obj.Level }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">日志时间:</Col>
      <Col class="value" span="9">{{ obj.LogDate }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">日志Url:</Col>
      <Col class="value" span="20">{{ obj.Url }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">请求参数:</Col>
      <Col class="value" span="20">{{ obj.QueryString }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">日志内容:</Col>
      <Col class="value" span="20">{{ obj.Content }}</Col>
    </Row>
  </Drawer>
</template>
<script>
export default {
  name: "log-detail-drawer",
  data() {
    return {
      isShow: false,
      obj: {},
    };
  },
  methods: {},
  computed: {},
};
</script>
